<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Reports</a></li>
      <li class="breadcrumb-item"><a href="#">Companies Commission</a></li>
    </ol>
    <h4 class="page-header">Companies Commission</h4>
    <div class="panel panel-inverse">
      <div class="panel-body">
        <div class="row">
          <div class="form-group col-md-5">
            <label>Start Date</label>
            <date-picker
                v-model="start"
                format="yyyy-MM-dd"
                input-class="form-control bg-white"
                required
            />
          </div>
          <div class="form-group col-md-5">
            <label>End Date</label>
            <date-picker
                v-model="end"
                format="yyyy-MM-dd"
                input-class="form-control bg-white"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="card border-0 bg-dark text-white text-truncate mb-3">
              <div class="card-body">
                <div class="mb-3 text-grey">
                  <b class="mb-3">Commission Summary</b>
                </div>
                <div class="d-flex align-items-center mb-1">
                  <h2 class="text-white mb-0"></h2>
                  <div class="ml-auto">
                    <apexchart :options="conversionRateChart.options"
                               :series="conversionRateChart.series"
                               height="28px"></apexchart>
                  </div>
                </div>
                <div class="d-flex mb-1">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-circle text-red f-s-8 mr-2"></i>
                    Commission Value
                  </div>
                  <div class="d-flex align-items-center ml-auto">
                    <div class="text-grey f-s-11"><i class="fa fa-caret-up"></i> KES
                      {{ bidbond_value.toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card border-0 bg-dark text-white text-truncate mb-3">
              <div class="card-body">
                <div class="mb-2 text-grey">
                  <b class="mb-2">Top 3 companies</b>
                </div>
                <div v-for="(com,key) in top3" :key="key" class="d-flex 100 mb-1">
                  <div class="d-flex text-capitalize w-75">
                    <i class="fa fa-circle text-red f-s-8 mr-1 mt-1"></i>
                    <span class="text-grey f-s-11">{{ com.name | truncate(30, '...') }}</span>
                  </div>
                  <div>KES {{ $number.format(com.commission) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card border-0 bg-dark text-white text-truncate mb-3">
              <div class="card-body">
                <div class="mb-3 text-grey">
                  <b class="mb-3">Comparative commission</b>
                </div>
                <div class="d-flex">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-circle text-lime f-s-8 mr-2"></i>
                    Last year same month
                  </div>
                  <div class="d-flex align-items-center ml-auto">
                    <div class="text-grey f-s-11">
                      <i :class="[ percent > 0 ? 'fa-caret-up' : 'fa-caret-down']"
                         class="fa"></i>
                      {{ percent }}%
                    </div>
                  </div>
                </div>
                <div style="margin-bottom: 37px"></div>
              </div>
            </div>
          </div>
        </div>
        <vue-good-table
            :columns="columns"
            :lineNumbers="true"
            :pagination-options="{ enabled: true }"
            :rows="commissions"
            :search-options="{ enabled: true}"
        >
          <div slot="table-actions">
            <download-excel
                v-if="reports.length"
                :data="reports"
                :title="'Companies Commission'"
                class="btn btn-primary"
                name="companies commission.xls"
            >
              <i class="fa fa-file-excel"></i> Export Excel
            </download-excel>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vuejs-datepicker";
import moment from 'moment/src/moment';
import {mapGetters} from "vuex";
import helper from "@/mixins/helper";

export default {
  mixins: [helper],
  components: {
    DatePicker
  },
  data() {
    return {
      columns: [
        {
          label: 'Company',
          field: 'name',
        },
        {
          label: 'CUR',
          field: 'currency',
        },
        {
          label: 'Amount',
          field: 'amount',
          formatFn: this.formatFn
        },
        {
          label: 'Commission',
          field: 'commission',
          formatFn: this.formatFn
        },
        {
          label: 'Bid Bonds',
          field: 'bidbond_count',
          formatFn: this.formatFn
        },
      ],
      start: moment().startOf('Year').toDate(),
      end: moment().format("Y-MM-DD"),
      bidbond_value: 0,
      conversionRateChart: {
        options: {
          chart: {
            type: 'line',
            width: 160,
            height: 28,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: 'smooth',
            width: 3
          },
          fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 1,
              opacityTo: 1,
              colorStops: [{
                offset: 0,
                color: '#ff5b57',
                opacity: 1
              },
                {
                  offset: 50,
                  color: '#f59c1a',
                  opacity: 1
                },
                {
                  offset: 100,
                  color: '#90ca4b',
                  opacity: 1
                }]
            },
          },
          xaxis: {
            crosshairs: {
              width: 1
            },
          },
          tooltip: {
            theme: 'dark',
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function () {
                  return '';
                }
              },
              formatter: (value) => {
                return value + '%'
              },
            },
            marker: {
              show: false
            }
          },
          responsive: [{
            breakpoint: 1500,
            options: {
              chart: {
                width: 120
              }
            }
          }, {
            breakpoint: 1300,
            options: {
              chart: {
                width: 100
              }
            }
          }, {
            breakpoint: 1200,
            options: {
              chart: {
                width: 160
              }
            }
          }, {
            breakpoint: 900,
            options: {
              chart: {
                width: 120
              }
            }
          }, {
            breakpoint: 576,
            options: {
              chart: {
                width: 180
              }
            }
          }, {
            breakpoint: 400,
            options: {
              chart: {
                width: 120
              }
            }
          }]
        },
        series: [{
          data: [2.68, 2.93, 2.04, 1.61, 1.88, 1.62, 2.80]
        }]
      }
    }
  },
  created() {
    this.$store.dispatch('fetchCommissions', {from: this.start, to: this.end});
  },
  watch: {
    commissions() {
      let bid_price = 0;
      this.commissions.forEach(b => {
        bid_price += parseInt(b.amount);
      });
      this.bidbond_value = bid_price;
    },
    period() {
      if (this.start && this.end) {
        this.$store.dispatch('setCommissions', {from: this.start, to: this.end});
      }
    },
  },
  computed: {
    ...mapGetters({
      commissions: 'getCommissions',
      reports: 'getCommissionsFormatted',
      companies: 'getCommCompanies',
      percent: 'getPercent',
      top_companies: 'getTopCompanies',
    }),
    period() {
      return [this.start, this.end].join();
    },
    top3() {
      let p = this.commissions;
      return this.commissions.length ? p.sort((a, b) => (parseInt(a.amount) < parseInt(b.amount)) ? 1 : -1).slice(0, 3) : [];
    }
  },
  methods: {
    randomScalingFactor() {
      return Math.round(Math.random() * 100)
    }
  }
}
</script>