<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Failed Registration</a></li>
    </ol>
    <h1 class="page-header">Failed Registration</h1>
    <panel noButton="true" title="Failed Registration Report" variant="panel-default">
      <table class="table table-striped">
        <thead>
        <tr>
          <th>#</th>
          <th>First Name</th>
          <th>Middle Name</th>
          <th>Last Name</th>
          <th>Reason </th>
          <th>Type </th>
          <th>Unique ID </th>
          <th>Phone Number </th>
          <th>Date </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(fail, i) in fails" :key="i">
          <td>{{ pagination.from + i }}</td>
          <td>{{ fail.first_name}}</td>
          <td>{{ fail.middle_name}}</td>
          <td>{{ fail.last_name}}</td>
          <td>{{ fail.reason}}</td>
          <td>{{ fail.type === 1 ? 'AML' : 'Banlist' }}</td>
          <td>{{ fail.user_unique_id}}</td>
          <td>{{ fail.phone_number}}</td>
          <td>{{ fail.created_at }}</td>
        </tr>
        </tbody>
      </table>
      <pagination
          v-if="pagination.last_page > 1"
          :offset="5"
          :pagination="pagination"
          @paginate="fetch"
      />
    </panel>
  </div>
</template>

<script>
export default {

  data() {
    return {
      fails: [],
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/registration-fails?page=' + this.pagination.current_page).then(response => {
        if (!Object.prototype.hasOwnProperty.call(response.data, "data")) return;
        this.fails = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
    },
  }
}
</script>